import React, { useEffect } from 'react';
import SEO from '../components/Seo';
// import { Link } from 'gatsby';

export default function CookiePolicyPage() {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.async = true;
    // script.defer = true
    script.src =
      'https://consent.cookiebot.com/e10d8fd8-83ea-4ac7-baa8-e89ef5dc0bd6/cd.js';
    if (typeof document !== 'undefined') {
      document.getElementById('cookiebot-cookie-policy')?.appendChild(script);
    }
  }, []);

  return (
    <>
      <SEO
        title="Cookie Policy"
        description="This website uses cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic."
      />
      <section className="container page-m-t page-m-b">
        <div className="row">
          <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-lg-offset-1">
            <h1>Cookie Policy</h1>
            <div id="cookiebot-cookie-policy" />
          </div>
        </div>
      </section>
    </>
  );
}
